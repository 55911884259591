const theme = {
  space: [0, 4, 8, 16, 24, 32, 40, 64, 128, 256, 512],
  breakpoints: ['576px', '768px', '992px', '1200px'],
  fontSizes: [12, 14, 16, 18, 24, 28, 40, 48, 64, 72],
  color: {
    green: '#1d3c34',
    gold: '#b3a37c',
    greyish: '#aaaaaa',
    gray: '#2c2c2c',
    white: 'white',
    black: '#000000',
    black44: 'rgba(0,0,0,0.44)',
    black80: 'rgba(0,0,0,0.8)',
  },
};

export default theme;
