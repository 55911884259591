import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Box from './Atoms/Box';
import logo from '../assets/images/logo-white.png';

const StyledNavbar = styled.nav`
  position: absolute;
  width: 100%;
  padding: 8px;
  background: transparent;
  color: ${({ theme }) => theme.color.white};
  .navbar-end {
    display: flex;
    align-items: stretch;
    margin-left: auto;
  }
  .navbar-item {
    img {
      width: 100%;
      max-height: none;
    }
  }
`;

const StyledItems = styled.div`
  border-top: 2px solid transparent;
  padding-top: 4px;
  margin: 0 4px;
  transition: border-top-color 200ms ease;
  &:hover {
    border-top-color:  white;
  }
`;

const Navbar = () => {
  const intl = useIntl();

  return (
    <StyledNavbar className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <Box width={40} height={40}>
            <img src={logo} />
          </Box>
        </a>
      </div>
      <div className="navbar-end">
        <a href="#">
          <div className="navbar-item">
            <StyledItems>
              <FormattedMessage id="navbar.home" defaultMessage="Home" />
            </StyledItems>
          </div>
        </a>
        <a href="#about">
          <div className="navbar-item">
            <StyledItems>
              <FormattedMessage id="navbar.about" defaultMessage="About" />
            </StyledItems>
          </div>
        </a>
        <a href="#contact">
          <div className="navbar-item">
            <StyledItems>
              <FormattedMessage id="navbar.contact" defaultMessage="Contact" />
            </StyledItems>
          </div>
        </a>
      </div>
    </StyledNavbar>
  );
};

export default Navbar;
